<template>
    <div  style="margin-top: 80px">
    <div class="page-breadcrumb">
        <BasePageBreadCrumb :pages="pages" title="relatórios - por empreendimento" :menu="menu"/>
        <BaseTotalReview :active="active" :inactive="inactive" :total="total" title="empreendimentos" @export="exportData" />

    </div>
   <div class="container-fluid qsuite-c-fluid-0 bg-white mt-4 pt-4">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-11 pt-1 pb-3">
                    <div class="row">
                        <div class="col-10 col-md-5 col-lg-5 pr-md-0 pr-0">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Buscar por nome" v-model="name">
                            </div>
                        </div>
                        <div class="col-1 col-md-auto text-center align-self-center pl-md-2 pl-0 pr-0">
                            <p class="font-11 color-1">ou</p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-5 pr-md-0">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Por ocorrência" v-model="occurrency">
                            </div>
                        </div>
                        <div class="col-12 col-md-2 col-lg-auto text-right">
                            <div class="form-group d-flex">
                                <button type="submit" class="btn btn-info" @click="fetchReports">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-4">
                    <div class="d-inline-flex align-items-center pt-3">
                        <h2 class="text-dark mb-1 font-weight-medium" style="line-height: 16px;">{{ name }}<span class="font-12 color-1" v-show="false"> | 002.232.123-09</span><span class="font-11 font-b-4"><br> | TOTAL DE REGISTROS <b>{{ pagination.itens }}</b></span></h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 pt-4">
                    <BaseReviewTable :data="reports" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <BasePagination
                        v-if="reports.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import DashboardService from "@/services/resources/DashboardService";
const service = ReportService.build();
const serviceDashboard = DashboardService.build();

export default {
    data(){
        return{
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'por empreendimento',
                    to: 'ReviewEnterprise'
                }
            ],
            menu:[],
            reports: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            name: null,
            occurrency: null,
            active: 0,
            inactive: 0,
            total: 0
        }
    },
    
    methods:{
        exportData(type){
            let data = {
                export: type
            }

            service
            .search(data)
            .then(resp => {
                window.open(resp.url, '_blank').focus();
            })
            .catch(err => {
                console.log(err)
            })
        },
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchReports();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchReports();
        },
        fetchReports(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.name || this.occurrency){
                data.enterprise = this.name || this.occurrency;
            }
        
            service
            .search(data)
            .then(resp => {
                this.reports = resp.data;
                this.pagination.totalPages = resp.last_page;
                this.pagination.itens = resp.total;
            })
            .catch(err => {
                console.log(err)
            })
        },
        fetchDasboard(){

            let data = {
                type: 'ENTERPRISE'
            }

            serviceDashboard
            .search(data)
            .then(resp => {
                this.active = resp.active;
                this.inactive = resp.inactive;
                this.total = resp.active + resp.inactive;
            })
            .catch(err => {
                console.log(err)
            })
        },
    },
    mounted() {
        this.fetchReports();
        this.fetchDasboard();
    }
}
</script>